import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import ishan from "../../assets/ishanpng.png"
import brands from "../../assets/simon.jpg"
import banking from "../../assets/banking.png"
import group from "../../assets/group.png"
const Logos = () => {
    const images = [
        ishan,group,
        brands, banking ];

    return (
        <Container>
            <Col xs={12} lg={10} className='mx-auto py-5'>
                <Row>
                    {images.map((image, index) => (
                        <Col key={index} xs={12} lg={3} className='text-center my-3'>
                            <Image src={image} className='mx-auto' width={180} height={"auto"} />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Container>
    )
}

export default Logos
