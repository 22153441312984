import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const Adopt = () => {
    const services = [
        {
            title: 'Trade Finance',
            text: 'Our trade finance solutions are available worldwide contingent in the issuance and/or monetisation of financial instruments, incluiding supplychain / transaction funding used by companies to facilitate international trade and commerce: '
        },
        {
            title: 'Personal & Corporate Banking',
            text: 'With the development of our fintech platform,Truvest Capital Market GMBH now offer companies and individuals easy access to both traditional and new fintech banking products.'
        },

        {
            title: 'Trade Finance Private Advisory',
            text: 'Truvest Capital provide expert guidance and strategic advice to corporations and private high networth individuals, relating to opportunities to participate in secured funding of lucrative trade finance transactions.',
            long:true
        },
        
    ];
    return (
        <>
            <div className="hero py-lg-5 py-2">
                <Container className='py-lg-5 py-3'>
                    <Row>
                        <Col xs={12} lg={6} className='d-flex align-items-center'>
                            <div className='text-center text-lg-start bg-white p-lg-5 p-2'>
                                <p className='top-text pt-lg-0 pt-4 text-muted'>PRACTICE AREAS</p>
                                <h1 className='py-2'>ADOPT FLEXIBLE APPROACHES FOR CLIENTS</h1>
                                <p>With a strong focus on customer satisfaction, security and financial innovation, we strive to deliver tailored solutions to meet the unique needs of our clients in the ever-evolving global market.</p>
                                <div className='mt-5 '>
                                <Link to="/services" className=' text-decoration-none text-black' >See in details</Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Row>
                                {services.map((service, index) => (
                                    <Col xs={12} lg={service.long?12:6} key={index}>
                                        <Card className='bg-transparent border-0 text-center adopt-card my-3'>
                                            <Card.Body>
                                                <Card.Title className='pb-2'>{service.title}</Card.Title>
                                                <Card.Text>
                                                    {service.text}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Adopt