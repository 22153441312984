import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PageHeading from '../shared/PageHeading';

const cardData = [
    {
        title: "International trade financing",
        text: (
            <>
                <p><span className='fw-bold'>Our international trade financing service provides tailored financial solutions</span> to assist businesses in managing the complexities of international trade. From letters of credit to trade finance solutions. we offer comprehensive support to facilitate smooth and secure transactions, to both the importer and exporter, but more importantly 100% security to our capital contributors - Our INVESTORS.</p>
                <p>The function of our trade finance service is to participate as a third-party in a secure and verified buy and sell transaction, removing the payment / settlement risk and the supply / delivery risk.</p>
                <p>Truvest provides the exporter with receivables or payment according to the agreement, while assisting the importer with logistic credit to receive and manage a controlled fulfillment of the trade order. Creating a secured transaction for the exporter and importer, with secured financial returns to our capital contributors.</p>
            </>
        )
    },
    {
        title: "Capital Contribution",
        text: (
            <>
                <p>What is a Truvest Capital Contributor?</p>
                <p><span className='fw-bold'>At Truvest we refer to our investors as capital contributors</span> because the term investor is not quite an accurate term,</p>
                <p>due to the following:</p>
                <ol>
                    <li>Our secured transaction opportunities are offered ONLY via invitation, to verified high networth individuals and corporations.</li>
                    <li>A capital contribution is the cash given to Truvest in exchange for an equity interest in an ongoing trade finance transaction, funding the transaction facilitation to a successful conclusion. A capital contributor is the named participant providing the cash to fund the trasaction. So a capital contributor is partnering with Truvest in a trade rather than an investor.</li>
                    <li>Each verified capital contributor will receive a financial instrument issued to their nominated bank account or held to order on their behalf, guaranteeing / covering 100% of the capital contributors investment. This means that at the end of the term of the transaction, if there is a delay or a potential risk of non-performance, our capital contributor's will have an independent right to instruct their bank to debit the instrument being held, providing the return of their investment capital from the financial instrument issuing bank, making our capital contribution program 100% secure..</li>
                </ol>
            </>
        )
    },
    {
        title: "Contributors & Truvest Fintech Banking Services",
        text: (
            <>
                <p><span className='fw-bold'>Truvest operate the only fintech platform </span> you'll ever need. Our online banking platform is user-friendly, designed for our capital contributors to utilize for depositing their contributions into their own online account and receiving the agreed profits, as well as any other standard banking services you wish to use your account for</p>
                <ol>
                    <li>Our banking platform is very easy to navigate, you can view your account balances, transaction history, and statements, and even set up direct debits, standing orders and custom alerts to help you stay on top of your finances.</li>
                    <li>Be in complete control over your money with our online banking platform, protected with the latest encryption technology and multi-factor authentication, giving you peace of mind when managing your finances.</li>
                    <li>Whether you’re at home, at work, on the go, providing a capital contribution for our lucrative trade finance transactions or your own private banking needs, our online banking platform is accessible from your computer, tablet, or mobile device. Virtual or physical cards ⎯ it’s your choice, or why not have both.</li>
                    <li>Accept payments from customers around the world directly to your account on our online banking platform and easily and securely transfer funds around the world at competitive rates. Link your Bank account to your online banking for seamless and secure online payments.</li>
                </ol>
            </>
        )
    }
];

const ServicesCard = () => {
    return (
        <>
            <Container>
                <PageHeading heading="services" />
                <Row className='py-3'>
                    {cardData.map((card, index) => (
                        <Col xs={12} lg={4} key={index}>
                            <Card className='border-0 bg-transparent service-card'>
                                <Card.Body className='px-0'>
                                    <Card.Title className='text-uppercase'>{card.title}</Card.Title>
                                    <Card.Text>{card.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}

export default ServicesCard;
