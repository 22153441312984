import React from 'react'
import DynamicHero from '../components/shared/DynamicHero'
import { Col, Container, Row } from 'react-bootstrap'
import ContactInfo from '../components/contact/ContactInfo'
import ContactForm from '../components/contact/ContactForm'
import PageHeading from '../components/shared/PageHeading'

const Contact = () => {
  return (
    <>
      <DynamicHero
        heading="Contact Truvest Capital Market"
        contact
      />
      <Container>
        <PageHeading heading="Contact" />
        <Row className='py-5'>
          <Col xs={12} lg={3}>
            <ContactInfo />

          </Col>
          <Col xs={12} lg={9}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Contact