import React from 'react'
import { Container } from 'react-bootstrap'
import PageHeading from '../components/shared/PageHeading'
import { Link } from 'react-router-dom'

const TermsOfService = () => {
    return (
        <>
            <Container>
                <PageHeading heading="Terms of Service" />
                <div className="disclaimer">
                    <h2 className='card-title py-4'>Terms of Service for Truvest Capital Markets GMBH</h2>
                </div>



                <p>Welcome to Truvest Capital Markets GMBH's terms of service! By using our services, you agree to the following terms and conditions:</p>

                <h5>Website Terms and Conditions of Use</h5>

                <ol>
                    <li>
                        <p>Terms</p>
                        <p>By accessing this Website, accessible from www.truvest.de, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.</p>

                    </li>

                    <li>
                        <p>Use License</p>
                        <p>Permission is granted to temporarily download one copy of the materials on Truvest Capital Market GmbH’s Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                        <ul>
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose or for any public display;</li>
                            <li>attempt to reverse engineer any software contained on Truvest Capital Market GmbH’s Website;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or “mirror” the materials on any other server.</li>
                        </ul>
                    </li>

                    <p>This will let Truvest Capital Market GmbH terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated, and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service have been created with the help of the Terms Of Service Generator.</p>
                    <li>
                        <p>Disclaimer</p>
                        <p>All the materials on Truvest Capital Market GmbH’s Website are provided “as is”. Truvest Capital Market GmbH makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Truvest Capital Market GmbH does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
                    </li>
                    <li>


                        <p>Limitations</p>
                        <p>Truvest Capital Market GmbH or its suppliers will not be held accountable for any damages that will arise with the use or inability to use the materials on Truvest Capital Market GmbH’s Website, even if Truvest Capital Market GmbH or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations of liability for incidental damages; these limitations may not apply to you.</p>
                    </li>
                    <li>
                        <p>Revisions and Errata</p>
                        <p>The materials appearing on Truvest Capital Market GmbH’s Website may include technical, typographical, or photographic errors. Truvest Capital Market GmbH will not promise that any of the materials in this Website are accurate, complete, or current. Truvest Capital Market GmbH may change the materials contained on its Website at any time without notice. Truvest Capital Market GmbH does not make any commitment to update the materials.</p>
                    </li>
                    <li>
                        <p>Links</p>
                        <p>Truvest Capital Market GmbH has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Truvest Capital Market GmbH of the site. The use of any linked website is at the user’s own risk.</p>
                    </li>
                    <li>


                        <p>Site Terms of Use Modifications</p>
                        <p>Truvest Capital Market GmbH may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
                    </li>

                    <li>


                        <p>Your Privacy</p>
                        <p>Please read our Privacy Policy.</p>
                    </li>
                    <li>

                        <p>Governing Law</p>
                        <p>Any claim related to Truvest Capital Market GmbH’s Website shall be governed by the laws of de without regards to its conflict of law provisions.</p>
                    </li>
                </ol>

                <p><b>Terms and Conditions Fintech Services</b>Truvest Capital Market which is a Trading Name of Valens Pay Limited (“Valens Pay” or “the Company”)</p>

                <h5>INFORMATION ABOUT US</h5>
                <p>Truvest Capital Market is a Trading Name of Valens Pay Limited which is licensed by and registered with FINTRAC as a Money Service Business with FINTRAC license no. M21914872 as required by the Canadian Federal Law combating money laundering and terrorism financing for businesses such as Truvest Capital Market in the financial sector. Any licensing or regulatory enquiries or complaints about the Company and its services should be addressed to the Company at its Registered Address.</p>

                <p>As used in these Terms and Conditions, “Truvest Capital Market” or “Valens Pay” or “Valens” or “the Company” or “us” or “we” refers to the company Valens Pay Limited, registered in Canada with the British Columbia Register under the company number BC1278918 with its registered address at 302-540 Lawrence Avenue, Kelowna V1Y 6L7, British Columbia, Canada. For the purposes of these Terms and Conditions, the Company is referred to from time to time as Truvest Capital Market.</p>

                <p>Truvest Capital Market is not a bank and, as such, is not part of a depositor protection scheme. Truvest Capital Market is an Anti-Money Laundering and Fintech introducing platform which connects its members to licensed third-party service partners.</p>

                <p>When you register on the Truvest Capital Market platform/s you consent to your data being shared immediately with third parties who we have contracted with to offer and provide you with their services. Your identity verification information and documents will be shared with some or all of our third-party service providers, some of whom may be disclosed in the Truvest Capital Market Privacy Statement as varied from time to time. An e-wallet will be opened for you when you successfully register with Truvest Capital Market, which may be with one of our third-party service providers. When you perform other actions via the Truvest Capital Market platform, your identity verification information will most likely be securely shared with the other relevant licensed service partners in order to provide the services. Any and all customer funds are deposited with third-party providers and are securely held by licensed banks and/or Central Banks.</p>

                <p>By using this website (“Site”), registering for a Truvest Capital Market Account (“Account”) or using any other Truvest Capital Market Services, you ("you, your, or yourself") are agreeing to accept and comply with these Terms and Conditions contained herein ("Terms and Conditions"). The Terms and Conditions include policies set out in Terms and Conditions, Data Policy, privacy policy AML CTF policy and other policies and you should read them all in their entirety and carefully on every occasion before using this Site or any of the Truvest Capital Market Services.</p>

                <p>Truvest Capital Market is a trademark of Valens Pay Limited.</p>

                <p>References to Truvest Capital Market are also references to its owners, directors, investors, employees.</p>

                <p>Truvest Capital Market is an AML and introducing platform which provides Customers with a Truvest Capital Market account and connects its users to licensed service partners for other services. When you register on Truvest Capital Market your identity verification information and documents are shared with parties some of whom are disclosed in the Privacy Policy, AML CTF Policy, Data Policy and other similar policies and varied from time to time. You will not be notified of any changes to any of our policies and terms, it is for you to read all terms before using the platform to ensure you are aware of the terms and conditions in place from time to time. The platform is owned by Valens Pay Limited.</p>

                <h5>ACKNOWLEDGMENTS</h5>
                <p>By using this website (“Site”), registering for a Truvest Capital Market Account (“Account”) or using any other Truvest Capital Market Services, you (“you, your, or yourself”) are agreeing to accept and comply with the Terms and Conditions stated below (“Terms and Conditions”). You should read the entire Terms and Conditions carefully before using this Site or any of the Truvest Capital Market Services.</p>

                <p>By accessing or using the Service you agree to be bound by these Terms and Conditions. If you do not agree with and accept all the Terms and Conditions quoted herein, then you may not access and or use the Services of the platform.</p>

                <p>By accessing or using the Services promoted on the platform you will become introduced to certain third-party partners of our choosing with whom we have commercial arrangements. If you accept an introduction and apply for services described as being available to you on our platform, you hereby acknowledge that you do so at your own risk with no warranty from us whatsoever and that you are aware that you will be entering into a services agreement with a third party and not with the Company.</p>

                <p>You acknowledge that it is your responsibility to ensure that the services provided by third parties are appropriate for you and your needs. You acknowledge that the Company may receive initial and ongoing fees and commissions from introductions made to third parties and from business. By accessing or using the Services promoted on the platform you will become introduced to certain third-party partners of our choosing with whom we have commercial arrangements.</p>

                <p>If you accept an introduction and apply for services described as being available to you on our platform, you hereby acknowledge that you do so at your own risk with no warranty from us whatsoever and that you are aware that you will be entering into a services agreement with a third party and not with the Company.</p>

                <p>You acknowledge that it is your responsibility to ensure that the services provided by third parties are appropriate for you and your needs. You acknowledge that the Company may receive initial and ongoing fees and commissions from introductions made to third parties and from business undertaken via third parties and the Company will only disclose the details of those fees and commissions where required to do so by law. You acknowledge that when following a link to services via the platform that you may in fact leave the Company’s platform, website and/or URL and you may become hosted upon the IT systems of the introduced party which may be in a different country to both you and the Company.</p>

                <p>Truvest Capital Market agrees to be bound by these Terms and Conditions. If you do not agree with and accept all the Terms and Conditions quoted herein without amendment, then you may not access and or use the Services of the platform. Depending on your country of residence, you may not be able to use all the functions of the Site. It is your responsibility to follow the rules and regulations applicable in your country of residence and/or country from which you access this Site and Services. As long as you agree to and comply with these Terms and Conditions, Truvest Capital Market grants you the personal, non-exclusive, non-transferable, non-sublicensable, and limited right to enter and use the Site and the Service.</p>

                <h5>IF YOU DO NOT OR YOU CANNOT ACCEPT THE TERMS AND CONDITIONS OUTLINED IN THIS AGREEMENT, DO NOT ACCESS THIS SITE AND DO NOT USE OUR SERVICE/S.</h5>

                <h5>DEFINITIONS</h5>
                <div>
                    <p>Account (or Profile): The contractual arrangement wherein an individual or a corporation becomes a Truvest Capital Market Member by accepting the Company’s Terms and Conditions and Privacy Policy, and applying to use Truvest Capital Market ’s third party’s Services, including the option to open bank account, purchase a debit card, to perform associated Transactions and use Truvest Capital Market and/or Truvest Capital Market partners services.</p>
                    <p>Beneficial Owner: Any natural person who owns the Client (a legal person or a foreign undertaking) or controls the Client and/or the natural person on whose behalf a transaction or activity is being conducted.</p>
                    <p>Buyer(s): Member(s) that are submitting an offer to buy services through the Service.</p>
                    <p>Commission or Fee: The charges or fees payable by you to us for the Services as set out in the Terms and Conditions and its supplements or on a transaction basis.</p>
                    <p>Company: Means Valens Pay Limited and its brand Truvest Capital Market, a Canadian company registered with the British Columbia Companies Register under the company number BC1278918 and with Money Service Business license no. M21914872, issued by FINTRAC, Canada.</p>
                    <p>Member(s) or Client(s): A natural or legal person, accepted by the Company as its Client to whom services will be provided by the Company under the Terms.</p>
                    <p>Personal Data: Information that identifies an individual, such as name, address, e-mail address, trading information, and banking details. “Personal Data” does not include anonymized and/or aggregated data that does not identify a specific user.</p>
                    <p>Price: The “price per service” for which Members are willing to purchase or sell, using the Service in a purchase or sell transaction. The Price may be expressed in any of the currencies deposited by Members in their Account and supported by the Service. See our Site for a full list of services.</p>
                    <p>Seller(s): Member(s) that submit an offer to sell through the Service.</p>
                    <p>Service(s): The technological platform, functional rules, client KYC & AML verification and market managed by Truvest Capital Market to permit Sellers and Buyers to purchase and sell Services provided by licensed partners of Truvest Capital Market.</p>
                    <p>Transaction: Includes the following (as available from time to time):
                        <ul>
                            <li>The action between the Member to Buy and/or Sell currencies at the then-current market rate.</li>
                            <li>The transfer of currencies among Members (“Currency Transfer Transaction”); and</li>
                        </ul>
                    </p>
                    <p>Transaction Price: The total price paid by the Buyer for each Transaction performed through the Service.</p>
                </div>

                <h5>BUSINESS MODEL AND SUPERVISION</h5>
                <p>The business model and Service(s) of Truvest Capital Market is to act as an Anti-Money Laundering Licensed Money Services Provider and fintech platform for companies and individuals. The Company aims to facilitate the introduction of its customers and members who have met the Anti Money Laundering standards of its authorizing body FINTRAC, to third-party providers to facilitate, through their platforms, enabling customers to open bank account(s) and/or order debit card(s) for Members, and/or convert funds between different currencies among other services, as available from time to time as provided by third parties and not Truvest Capital Market itself.</p>

                <p>Aerapass Group is a Third-Party provider, and by using our services, you have accepted the Terms of Aerapass Group as well. Their full terms can be found here; <Link className='text-black' href="https://aerapass.io/terms.html">https://aerapass.io/terms.html</Link>.</p>
                <p>UAB Pyrros Lithuania is a Third-Party provider, and by using our services, you have accepted the Terms of UAB Pyrros Lithuania as well. Their terms can be found here; <Link className='text-black' href="https://www.pyrrospayments.com/privacy-policy">https://www.pyrrospayments.com/privacy-policy</Link>.</p>
                <p>Sum and Substance Ltd. is a Third-Party provider, and by using our services, you have accepted the Terms of Sum and Substance Ltd. as well. Their terms can be found here; <Link className='text-black' href="https://sumsub.com/privacy-notice-service/">https://sumsub.com/privacy-notice-service/</Link>.</p>

                <h5>ACCOUNT APPLICATION AND VERIFICATION</h5>
                <p>The creation and use of your Account is subject to verifications, as required by statutory and regulatory obligations incumbent on Truvest Capital Market. You agree to provide us with the information we request for the purposes of identity verification, compliance with know-your-customer rules, as well as detection of money laundering, terrorism financing, fraud or any other financial crime. The requested information may include Personal Data (please refer to our Privacy Policy). By providing us with the information we request, you confirm that it is true and accurate, and agree to inform us in case of change concerning such information. Your Account will be blocked until we are satisfied with the information you have provided and determine in our sole discretion that it is sufficient to validate your Account. In the meantime, you will not be allowed to terminate your Account or request the deletion of the Personal Data processed in the course of verification operations. Truvest Capital Market utilizes and thus securely shares information with a third-party KYC/AML verification tool in addition to its internal identification screening procedures.</p>

                <p>It is understood that we are not required or under any obligation (and may be unable under Applicable Regulations, including without limitation anti-money laundering checks, etc.) to accept a person and / or a Corporation as our Client. It is further understood that we reserve the right to impose additional due diligence requirements before we might accept Client(s) residing in certain countries or whenever this is required by the Company or our partner’s policies.</p>
                <p>Clients are only allowed to create only one Active Profile per Client with the Company. In the event that the Client has more than one Profile with the Company, the Company reserves the right to treat them as if they were under one Account and request the Client to choose the Main Profile in order for the duplicate Profile(s) to be terminated and/or merged (if technically possible). Failure to choose a Profile that is to remain will lead the Company to choose a Profile to keep and a Profile/s to keep, merge or close.</p>

                <h5>DATA PROTECTION</h5>
                <p>Privacy is very important to us. Full details of our Data Protection Policies can be found on our Privacy Policy page. We recommend that you read the Privacy Policy carefully, so that you know the data that we collect, how we use the data and who we share your data with.</p>

                <p>ACCOUNT OPENING ELIGIBILITY</p>
                <p>The Services are available to, and may only be used by, individuals or companies who can form legally binding contracts under the law applicable to their country of residence.</p>
                <p>By registering for an Account, you expressly represent and warrant:</p>
                <ul>
                    <li>That you have accepted these Terms and Conditions, AML CTF policies and Privacy Policy;</li>
                    <li>That you are at least 18 years of age;</li>
                    <li>That you are not a citizen or resident of the countries which the Company does not accept as Clients;</li>
                    <li>That you have the capacity to act, i.e., to create rights and obligations through your actions.</li>
                </ul>
                <p>Without derogating from the above, the Company reserves the right, acting reasonably, to suspend and/or refuse access to and use of the Company’s service(s) and/or system(s) and/or Platform to anyone in our sole and absolute discretion.</p>

                <h5>MAINTAINING YOUR ACCOUNT</h5>
                <p>The Company’s site/s are for your personal and non-commercial use only where you are an individual. Where you are a Corporation, the Company’s site/s are only for the commercial use by the Corporation and not for any personal use by the directors or employees or agents of the Corporation. We are vigilant in maintaining the security of our Site and Service. By registering with us, you agree to provide Truvest Capital Market with current, accurate, and complete information about yourself, as prompted by the registration process, and to keep such information updated. Failure to do so constitutes a breach of these Terms and Conditions which may result in immediate termination of your account on our Service. You further agree that you will not use any Account other than your own, access the Account of any other Member at any time, or assist others in gaining unauthorized access.</p>

                <p>The creation or use of Accounts without obtaining prior express permission from Truvest Capital Market will result in the immediate suspension of all said Accounts, as well as all pending purchase/sale offers. Any attempt to do so or to assist others (Members or otherwise), or the distribution of instructions, software, or tools for that purpose, will result in the Accounts of such Members being terminated. Termination is not the exclusive remedy for such a violation, and Truvest Capital Market may elect to take further action against you.</p>

                <p>You are also responsible for maintaining the confidentiality of your Account information, including your password, and safeguarding your account and all activity including Transactions that are posted to your Account. In case of transactions be aware that they might be irreversible once sent, so be sure to carefully confirm the details before initiating a transaction. Any actions on the Site, transactions, orders and operations initiated from your Account or using your password (1) will be considered to have been made by you, and (2) is irrevocable once validated using your password or made through your Account. If there is suspicious activity related to your Account, we may, but are not obligated to, request additional information from you, including authenticating documents, and freeze any transactions pending our review. You are obligated to comply with these security requests or accept the termination of your Account. You are required to notify Truvest Capital Market immediately of any unauthorized use of your Account or password, or any other breach of security, by email to <a href="mailto:info@tradestyle.com">info@tradestyle.com</a>. The account of any Member who violates these rules may be terminated and the Member held liable for losses incurred by Truvest Capital Market or any user of the Site.</p>
                <p>Lastly, you agree that you will not use the Service to perform criminal activity of any sort, including but not limited to money laundering, illegal gambling operations, terrorist financing, malicious hacking or any criminal or illegal activity.</p>

                <h5>TOP UP / ADD MONEY</h5>
                <p>You may be provided the option to add funds to your Account using Cards. Truvest Capital Market does not allow Card payment from or on behalf of third parties or anonymous transactions. The Card you choose to add funds to Truvest Capital Market Account(s) must belong to you and must be in your name. We do not accept card payments from people who are not you. We may require you to provide us with documents to verify the details, and the ownership of your payment method. If we cannot verify the details of your payment method, or if we cannot verify that the payment method belongs to you, you will not be able to deposit money into your Account, and therefore will not be able to use our Services.</p>

                <p>All transactions are checked and may be processed up to a certain amount, as it may be defined by the Company from time to time, automatically. First-time deposits may be processed automatically; however, it remains on the Company’s discretion to request further documents in order to establish and verify the ownership of the used card in case discrepancies have been detected upon manual ex-post check.</p>

                <p>The Company reserves the right to request the Client at any time to provide any documentation to confirm the source of funds deposited into the Client Account. The Company shall have the right to reject a deposit from the Client if the Company is not duly satisfied as to the legality of the source of funds.</p>
                <p>Lastly, the Company accepts no responsibility for fees or charges applied on any transaction by your financial institution and/or currency exchange rates resulting from the payment of such amount. Consequently, such fees or charges shall be fully borne by you.</p>

                <h5>REGISTRATION</h5>
                <p>By registering for an Account, you expressly represent and warrant:</p>
                <ul>
                    <li>That you have accepted these Terms and Conditions; and</li>
                    <li>That you are of legal age and have the full legal capacity to accept these Terms and Conditions</li>
                </ul>

                <h5>TRANSFER / WITHDRAWAL</h5>
                <p>You may withdraw funds from your Account at any time in accordance with the Terms and Conditions, using a bank transfer option (SEPA, FASTER PAYMENTS and/or international transfer). Truvest Capital Market will process the request provided the following requirements are met:</p>
                <ul>
                    <li>The withdrawal instructions include all necessary information;</li>
                    <li>The instruction is to make a bank transfer to the account of the Client (under no circumstances will payments to third party or anonymous accounts be accepted).</li>
                </ul>
                <p>You further acknowledge and accept that the expected destination of outgoing transfers/payments will be the same as with the expected destination of incoming of funds. You may not be allowed to withdraw your funds by any other method, or to any other country, apart from your country of residence.</p>

                <h5>FEES</h5>
                <p>Our fees include both fixed and variable charges. Some fees are one-off and other fees are disclosed on a per transaction basis.</p>
                <p>Other charges: The Company reserves the right to charge extra service fees for any documents requested by you for personal use such as but not limited to acknowledgment letters, account confirmation letters for tax purposes or communication retrieval other than already included in the dedicated section for data transfer on the Client’s portal. The Company will communicate the fees to you upon receiving a request for any of the requested documentation.</p>
                <p>Chargebacks and similar costs, that may arise from incorrect transactions will be borne by you.</p>

                <h5>CARD FEES</h5>
                <p>All card fees are managed and processed by Valens Pay London Ltd., 35 High Street Wimbledon, London, SW19 5BY England, Company no. 14572039. Any refunds or claims must be addressed to <Link className='text-decoration-none text-black' href="mailto:info@valenspay.com">info@valenspay.com</Link></p>

                <h5>CARD FEES REFUND POLICY</h5>
                <p>All Truvest Capital Market card fee refunds are managed and processed by Valens Pay London Ltd. Truvest Capital Market is not obliged and will not return fees to Clients due to wrong input or information from the Client during the onboarding process or during usage of the platform, be wrong industry, country, name etc., hence Truvest Capital Market is not responsible and will not refund onboarding or transactional fees for Client typing mistakes or wrong information. In the specific case of the onboarding fee, if mistakes are derived directly by Truvest Capital Market, the Client will get refunded the onboarding fee. If Truvest Capital Market cause an error, the Client must inform Truvest Capital Market at <Link className='text-decoration-none text-black' to="mailto:support@truvest.de">support@truvest.de</Link> no later than 10 days after the onboarding fee is charged to get the refund.</p>

                <h5>DEDICATED ACCOUNT OPENING</h5>
                <p>By using the Platform, you are required to agree to the respective Agreements, Terms and Conditions, and Privacy Policies of our authorized Truvest Capital Market partners, depending on the services you would like to use. You may be prompted to read and acknowledge these legal documents upon applying for such a product or service on or via the Truvest Capital Market Platform. Please read them carefully.</p>

                <h5>TERMINATION</h5>
                <p>The Terms and Conditions enter into force on the day you register for the Profile and are valid as long as you have an Account with us. We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms and Conditions.</p>
                <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account with us, you may simply discontinue using the Service.</p>
                <p>However, do note that you may not close your Account / terminate these Terms and Conditions to avoid an investigation. If you attempt to close your Account / terminate this agreement while Truvest Capital Market is investigating, we may freeze the Profile /Account (s) to protect all parties to the Truvest Capital Market services, its affiliates, or a third party against any liability. You will remain liable for any obligations related to your Truvest Capital Market Account and your activity even after it is closed.</p>

                <p>The termination of your Profile / Account will not affect any of our rights or your obligations arising under these Terms and Conditions. Any terms which by their nature should survive will survive the termination of your Profile / Account.</p>

                <h5>LIMITED RIGHT OF USE</h5>
                <p>Unless otherwise specified, all Materials on this Site are the property of Truvest Capital Market and are protected by copyright, trademark, and other applicable laws. You may view, print, and/or download a copy of the Materials from this Site on any single computer solely for your personal, informational, and/or non-commercial use, provided you comply with all copyright and other proprietary notices.</p>

                <p>The trademarks, service marks, and logos of Truvest Capital Market and others used in this Site are the property of Truvest Capital Market and their respective owners. The software, text, images, graphics, data, prices, trades, charts, graphs, video, and audio used on this Site belongs to Truvest Capital Market. The Trademarks and Materials should not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected, or distributed in any form or by any means, whether manual or automated. The use of any such Materials on any other Site or networked computer environment for any other purpose is strictly prohibited; any such unauthorized use may violate copyright, trademark, and other applicable laws, and could result in criminal or civil penalties.</p>

                <h5>AVAILABILITY OF SERVICES</h5>
                <p>All services are provided without warranty of any kind, either express or implied, and in particular without implied warranties of merchantability and fitness for a particular purpose. We do not guarantee that this Site will be available 100% of the time to meet your needs. We will strive to provide you with the Service as soon as possible, but there are no guarantees that access will not be interrupted or that there will be no delays, failures, errors, omissions, or loss of transmitted information.</p>
                <p>We will use reasonable endeavors to ensure that the Site can be accessed by you in accordance with these Terms and Conditions. However, we may suspend the use of the Site for maintenance and will make reasonable efforts to give you notice of this. You acknowledge that this may not be possible in an emergency, and accept the risks associated with the fact that you may not always be able to use the Site or carry out urgent transactions using your Account.</p>

                <h5>APIS AND WIDGETS</h5>
                <p>We may provide certain parties with access to specific data and information through our API (Application Programming Interface) or widgets. We also may provide widgets for your use to enter our data on your Site. You are free to use these in their original unmodified and unaltered state.</p>

                <h5>EXTERNAL WEBSITES</h5>
                <p>Truvest Capital Market makes no representations whatsoever about any external or third-party website you may access through the Site. Occasionally, the Truvest Capital Market website may provide references or links to other websites (“External Websites”). We do not control these External Websites or third-party sites or any of the content contained therein. You agree that we are in no way responsible or liable for the External Websites referenced or linked from the Truvest Capital Market website, including but not limited to website content, policies, failures, promotions, products, opinions, advice, statements, prices, activities and advertisements, services, or actions, and/or any damages, losses, failures, or problems caused by, related to, or arising from those sites. You shall bear all risks associated with the use of such content.</p>

                <p>External Websites have separate and independent Terms and Conditions and related policies. We request that you review the policies, rules, terms, and regulations of each site that you visit. It is up to you to take precautions to ensure that whatever you select for your use is free of items such as viruses, worms, Trojan horses, and other items of destructive nature.</p>

                <h5>FINANCIAL ADVICE</h5>
                <p>Truvest Capital Market does NOT provide any investment advice in connection with the Services described in these Terms and Conditions. Any decision to use Truvest Capital Market’s services or other available services through other Partners, is solely your decision and we shall not be liable for any loss suffered.</p>

                <h5>DATA PROTECTION</h5>
                <p>Privacy is very important to us. Full details of our Privacy Policy can be found on our Privacy Policy page. We recommend that you read the Privacy Policy carefully, so that you know the data that we collect, how we use the data and who we share your data with.</p>

                <h5>DISCLOSURES TO LEGAL AUTHORITIES AND AUTHORIZED FINANCIAL INSTITUTIONS</h5>
                <p>We may share your Personal Data with law enforcement, data protection authorities, government officials, and other authorities when:</p>
                <ul>
                    <li>Required by law;</li>
                    <li>We believe that disclosure is necessary to prevent damage or financial loss;</li>
                    <li>Disclosure is necessary to report suspected illegal activity; or</li>
                    <li>Disclosure is necessary to investigate violations of our Terms and Conditions or Privacy Policy.</li>
                </ul>
                <p>For further information on how we process your Personal Data, please refer to the Privacy Policy.</p>

                <h5>LIMITATION OF LIABILITY</h5>
                <p>To the extent permitted by law, Truvest Capital Market will not be held liable for any damages, loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, indirect, or consequential loss, unless the loss suffered arose from gross negligence or willful deceit or fraud. Nothing in these Terms and Conditions excludes or limits the liability of either party for fraud, death, or personal injury caused by its negligence, breach of terms implied by operation of law, or any other liability which may not be limited or excluded by law. Although Truvest Capital Market endeavors to provide accurate and timely information on the Site, the Site may not always be entirely accurate, complete, or current and may include errors. We may change or update the Site at any time without notice, and you should accordingly verify with independent sources all information before relying on it to take decisions or actions. You remain entirely responsible for your decisions and actions.</p>

                <p>We can face technical or system error which is beyond the Company’s reasonable control. There may occur events such as power supply, telecommunication issues, natural disasters etc. that cause system interruption beyond the service providers’ control. When such an error occurs during the execution of clients’ requests the Company accepts no liability with the exception of any gross negligence, fraudulent, dishonest or criminal conduct on the part of the Company, its directors, its officers or employees when acting within the scope of the service agreement (Terms and Conditions).</p>

                <p>Subject to the foregoing, Truvest Capital Market‘s aggregate liability for claims based on events arising from or in connection with any single Member’s use of the Site and/or Service, whether in contract or tort (including negligence) or otherwise, shall in no circumstances exceed the greater of either (a) the total amount held on Account for the Member making a claim less any amount of Commission that may be due and payable in respect of such Account; or (b) 100% of the amount of the Transaction(s) that are the subject of the claim less any amount of Commission that may be due and payable in respect of such Transaction(s).</p>
                <p>You should be also aware that in some cases intermediary banks may hold the funds you attempt to deposit or withdraw from your Account, which may delay the transfer of funds and which the Company has no control over. The Company will not be held liable for such actions or omissions by intermediary banks.</p>

                <h5>LEGAL DISCLAIMER</h5>
                <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
                <p>Truvest Capital Market, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                <h5>INDEMNITY</h5>
                <p>To the full extent permitted by applicable law, you hereby agree to indemnify Truvest Capital Market and its partners against any action, liability, cost, claim, loss, damage, proceeding, or expense suffered or incurred if directly or indirectly arising from your use of Truvest Capital Market’s Sites, your use of the Service, or your violation of these Terms and Conditions.</p>

                <h5>UNCLAIMED PROPERTY</h5>
                <p>If we hold cash or cryptocurrency, and we are unable to contact you and have no record of your use of the Truvest Capital Market Services for several years, applicable law may require us to report the cash or cryptocurrency as unclaimed property to the authorities in certain jurisdictions. We will try to locate you at the address shown in our records, but if we are unable to, we may be required to deliver any such cash or cryptocurrency to the authorities in certain jurisdictions as unclaimed property. We reserve the right to deduct a dormancy fee or other administrative charges from such unclaimed funds, as permitted by applicable law.</p>

                <h5>MISCELLANEOUS</h5>
                <p>If we are unable to perform the Services outlined in the Terms and Conditions due to factors beyond our control including but not limited to an event of force majeure, change of law, or change in sanctions policy, we shall not be liable for the Services provided under this agreement during the time period coincident with the event.</p>

                <h5>MODIFICATION OF TERMS</h5>
                <p>Truvest Capital Market reserves the right to change, add, or remove parts of these Terms and Conditions at any time and at its sole discretion. Your continued use of the Site following changes to the Terms and Conditions signifies that you accept and agree to the changes, and that all subsequent transactions by you will be subject to the amended Terms and Conditions.</p>

                <h5>CHANGES</h5>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time.</p>
                <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

                <h5>EMAIL COMMUNICATION</h5>
                <p>Unencrypted email messages sent over the Internet are not secure and Truvest Capital Market is not responsible for any damages incurred as a result of sending email messages in this way. We suggest existing Members always log in to the Truvest Capital Market site and use the internal support messaging system by clicking “Support” in the footer menu or, for non-Members, always send email in encrypted formats. You are welcome to send PGP encrypted emails to us. The instructions and keys to do so are available upon request. Please never send us or anyone else your password by email.</p>
                <p>If you send unencrypted or unsecured email or other types of communications to us, we may respond using the same channels and you hereby accept the risks associated therewith.</p>

                <p>Truvest Capital Market will never contact you from an email account that does not end with @truvest.de. If anyone, including a person that you know to be associated with Truvest Capital Market, ever contacts you via email with an address that does not end with @truvest.de, please notify our compliance department at <Link className='text-decoration-none text-black' href="mailto:support@truvest.de">support@truvest.de</Link> immediately.</p>

                <h5>RECORDING YOUR COMMUNICATION WITH US</h5>
                <p>You agree that we may record any telephone, email and chat conversations with you, as well as any other forms of communications, activities, and transactions, between you and us, and that the recordings may be used as evidence in any proceedings relating to our Agreement with you, any order you place, and/or trades executed. These records will be our sole property.</p>

                <h5>CONTACT US</h5>
                <p>If you are a Member, and have any questions relating to these Terms and Conditions, your rights and obligations arising from these Terms, and/or your use of the Site and the Service, your Account, or any other matter, please Contact Us via our internal secure messaging platform, from within your account by clicking “Support” in the footer after logging in or, if you cannot Contact Us using our internal secure messaging platform, you can email us via the contact form on the Contact Us page of our website. If you email us without using our internal secure messaging platform, please send PGP encrypted emails to us. The instructions and keys to do so are available upon request. For more information about email communications, see the section titled “Email communication” above.</p>

                <h5>CONSENT TO USE OF COOKIES</h5>
                <p>This website uses cookies to ensure you have the best experience. By continuing to use this site, you consent to our Cookie Statement. You can disable cookies at any time, by changing your browser settings. See our Cookie Statement for instructions on how to disable cookies, and the effect this will or may have on the Services.</p>

                <h5>JURISDICTION, GOVERNING LAW</h5>
                <p>The Terms and Conditions shall be governed and construed in accordance with the laws of Canada, without regard to its conflict of law provisions. The parties hereto agree to irrevocably submit to the exclusive jurisdiction of the courts of Vancouver, Canada.</p>

                <p>Our failure to enforce any right or provision of these Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms and Conditions is held to be invalid or unenforceable by a court, the remaining provisions of these Terms and Conditions will remain in effect. These Terms and Conditions constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.</p>

            </Container>
        </>
    )
}

export default TermsOfService