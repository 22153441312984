import React from 'react'
import Hero from '../components/home/Hero'
import Logos from '../components/home/Logos'
import Adopt from '../components/home/Adopt'

const Home = () => {
  return (
    <>
      <Hero />
      <Logos />
      <Adopt/>
    </>
  )
}

export default Home