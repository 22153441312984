import React from 'react'
import ServicesCard from '../components/services/ServicesCard'
import DynamicHero from '../components/shared/DynamicHero'

const Services = () => {
  return (
    <>
      <DynamicHero
        heading="Truvest Capital Market Services"
        small={"your benefits"}
        description={"Truvest Capital Market provides a flexible approach to ensure that clients receive the most appropriate solution"}
        service
      />
      <ServicesCard />

    </>
  )
}

export default Services