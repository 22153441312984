import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    const legalItems = [
        {
            title: 'TERMS OF SERVICE',
            text: 'Represented By Albert Rempel',
            additionalInfo: 'VAT-ID/Steuer-ID DE328403733',
            lei: 'LEI 98450088887BF68H7C56',
            href: "/termsofservice",
        },
        {
            title: 'PRIVACY POLICY',
            text: '',
            href: "/privacypolicy",
        },
        {
            title: 'DISCLAIMER',
            text: '',
            href: "/disclaimer",
        }
    ];
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Container className='py-4 footer'>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={12} lg={5}>
                                <div>
                                    <p className='py-2'>Truvest Capital Market</p>
                                    <p>Mottmannstr. 1-3 53842 Troisdorf Germany</p>
                                    <Link to="mailto:info@truvest.de">info@truvest.de</Link>
                                    <p>
                                        <Link to="truvest.de">truvest.de</Link>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} lg={5}>
                                <div>
                                    <p className='py-2'>Company Headquarter</p>
                                    <p>Sankt Augustin</p>
                                    <p>Registered Court</p>
                                    <p>District Court Siegburg, HRB 15784</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} lg={6}>
                        <Row>
                            {legalItems.map((item, index) => (
                                <Col key={index} xs={12} lg={4}>
                                    <div>
                                        <p className='py-2'>
                                            <Link to={item.href} className='heading'>
                                                {item.title}
                                            </Link>
                                        </p>
                                        <p>{item.text}</p>
                                        {item.additionalInfo && <p className='py-2'>{item.additionalInfo}</p>}
                                        {item.lei && <p>{item.lei}</p>}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='copyright text-center pt-3'>
                <p>&copy; {currentYear} COPYRIGHTS - TRUVEST CAPITAL MARKET</p>
            </Container>
        </>
    );
};

export default Footer;
