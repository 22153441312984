import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/logo.png";
import { Image } from 'react-bootstrap';

function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/services", label: "Services" },
    { path: "/transaction", label: "Transaction" },
    { path: "/contact", label: "Contact" },
    { path: "https://banking.truvest.de/en/Identity/Account/Login/?ReturnUrl=/", label: "Login/New Account" },
  ];

  return (
    <>
      <Navbar expand="lg" className="bg-white py-3">
        <Container>
          <NavLink to="/">
            <Image src={logo} alt="Logo" height={58} />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-none d-lg-flex">
              {navItems.map((item, index) => (
                <NavLink
                  className={({ isActive }) => "mx-3 mt-2 nav-link text-uppercase header-nav-link" + (isActive ? ' active' : '')}
                  key={index}
                  to={item.path}
                >
                  {item.label}
                </NavLink>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='mt-1'>Navigation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='px-0'>
          <Nav className="flex-column">
            {navItems.map((item, index) => (
              <NavLink
                className={({ isActive }) => "py-3 nav-link text-uppercase custom-nav-link" + (isActive ? ' active' : '')}
                key={index}
                to={item.path}
                onClick={handleClose}
              >
                {item.label}
              </NavLink>
            ))}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
