import React from 'react'

const PageHeading = ({ heading }) => {
    return (
        <div className='page-heading py-3'>
            <h2 className='text-uppercase'>{heading}</h2>
            <div className="line"></div>
        </div>
    )
}

export default PageHeading