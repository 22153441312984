import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PageHeading from '../shared/PageHeading';

const cardData = [
    {
        title: "Financial Instrument Issuance",
        text: (
            <>
                <p>As a capital contributor successfully onboarded with Truvest you will have the opportunity to participate in the financing of large financial instruments, for approved and secure transactions.</p>
                <p>Truvest provides flexible and tailored financial instrument issuance solutions to support verified corporations with their lucrative trade finance needs.</p>
                <p>Our team of experts will work with large corporates who have a proven track record in respect of their underlying trade finance requirement. </p>
                <p>to secure the ideal financial instrument that meets their specific needs. Truvest will secure a handsome percentage of the anticipated profit in the underlying transaction, providing a fixed equity stake to our capital contributors, securing our capital contributors investment towards the issuance of the required financial instrument.</p>
                <p>Our corporate partners access the financial instruments they need to grow and expand their business and our capital contributors have the peace of mind entering into a 100% secure transaction with a fixed equity stake in the underlying profit..</p>
            </>
        )
    },
    {
        title: "Precious Metals",
        text: (
            <>
                <p>Truvest has extensive expertise in all aspects of the physical and financial commodity trading markets. Partnering with capital contributors to fund commodities trades.</p>
                <p> along with our contractual counterparties, we assist in the logistic operations and physical deliveries' of the following:</p>

                <ul>
                    <li>Gold bars (1g, 10g, 20g, 31.1g, 100g, 1000g, 12,5kg)</li>
                    <li>Gold coins (1/10 oz, 1/4 oz, 1/2 oz, 1oz)</li>
                    <li>Silver Bars (31.1g, 100g, 250g, 500g, 1000g, 5000g, 15000g)</li>
                    <li>Silver coins (1oz, 2oz)</li>
                    <li>Platinum (per request)</li>
                    <li>Palladium (per request)</li>
                    <li>Copper (isotopic copper powder, copper sheets, copper cathodes)</li>
                    <li>Copper (isotopic copper powder, copper sheets, copper cathodes)</li>
                </ul>
                <p>The financing of such lucrative transactions provides access to regular and consistent returns on capital contributions, still providing the ring fenced participation of proceeds against the financial instruments, securing the underlying transaction and any associated investment.</p>
            </>
        )
    },
    {
        title: "Petroleum & Petrochemicals",
        text: (
            <>
                <p>Truvest are also committed to utilizing our capital contributor's funds to optimize the delivery, logistics, and sales of Petroleum & Petrochemical Products, on behalf of our verified Petroleum Partners, on a global scale. By strategically deploying these resources, we aim to enhance efficiency and reliability throughout the supply chain, ensuring timely and secure transactions. This approach not only strengthens our global presence but also safeguards the investments of our capital contributors, fostering mutual growth and success.</p>

                <p>Our strategy also involves securing investments through financial instruments, such as Bank Guarantees, Standby Letters of Credit and Documentary Letters of Credits issued by the purchasers of the products. These instruments serve as a guarantee for the transactions, providing an added layer of security for our capital contributors. Additionally, our financial contributors hold an equity stake in the profits generated from the sale of goods. This ensures that they directly benefit from the success of our operations.</p>

                <p>To further mitigate risks, we have implemented comprehensive credit insurance policies through third-party insurance brokers. These policies act as safeguards in the event of non-performance or transaction failure, offering financial protection against potential losses. By proactively managing risks and leveraging financial instruments and insurance solutions, we aim to uphold the integrity of our investments and maximize returns for our capital contributors.</p>
             
            </>
        )
    },
    {
        title: "Other GOODS",
        text: (
            <>
                <p>We are dedicated to facilitating supply chain financing for various commodities, leveraging our clients' capital contributions to optimize transactions and ensure investment security.</p>
                <p>Our comprehensive strategy encompasses a range of goods supplied by our partners, including:</p>

                <ul>
                    <li>Energy By-Products like Wood Pellets</li>
                    <li>Construction Materials</li>
                    <li>Steel and Steel Products</li>
                    <li>Road Associated Materials</li>
                    <li>Edible Oils (available in various grades and packaging upon request)</li>
                    <li>Grain Products such as Wheat (available upon request)</li>
                    <li>Sugar (including ICUMSA and EU Grades in both white and brown variants from different origins like EU, Brazil, and India)</li>
                    <li>Rice (offering different grades and origins upon request)</li>
                </ul>
                <p>To fortify our investment security measures, we implement similar transaction strategies for each commodity, including the issuance of financial instruments by purchasers, equity stakes in profits for our contributors, and comprehensive credit insurance policies managed by reputable third-party brokers. This approach ensures that our capital contributor's investments are safeguarded while optimizing the efficiency and reliability of our global supply chain operations.</p>
            </>
        )
    },
];

const TransactionCard = () => {
    return (
        <>
            <Container className='py-3'>
                <PageHeading heading="Transactions" />
                <Row className='py-3'>
                    {cardData.map((card, index) => (
                        <Col xs={12} lg={6} key={index}>
                            <Card className='border-0 bg-transparent service-card'>
                                <Card.Body className='px-0'>
                                    <Card.Title className='text-uppercase'>{card.title}</Card.Title>
                                    <Card.Text>{card.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}

export default TransactionCard;
