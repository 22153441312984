import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import Transaction from './pages/Transaction'
import Contact from './pages/Contact'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Disclaimer from './pages/Disclaimer'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'

const Navigation = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/services" element={<Services />}></Route>
                <Route path="/transaction" element={<Transaction />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/forgetpassword" element={<ForgotPassword />}></Route>
                <Route path="/disclaimer" element={<Disclaimer />}></Route>
                <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                <Route path="/termsofservice" element={<TermsOfService />}></Route>
                
            </Routes>
        </>
    )
}

export default Navigation