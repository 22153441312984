import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const DynamicHero = ({ heading, small, description, contact, transaction,service }) => {
    return (
        <>
            <div className={`services py-5 ${contact ? 'contactbg' : transaction ? 'transactionbg' : service?'servicebg':""}`}>
                <Container>
                    <Row>
                        <Col xs={12} lg={9} className='d-flex align-items-center py-5'>
                            <div className='text-center text-lg-start'>
                                <h1 className='py-2 py-lg-4'>{heading}</h1>
                                {small ?
                                    <div class="separator py-2">
                                        <div class="line d-lg-none d-block"></div>
                                        <small className='pe-2 ps-lg-0 ps-2'>{small}</small>
                                        <div class="line"></div>
                                    </div>

                                    : ""}

                                {description ?

                                    <p className='pt-3'>{description}</p> : ""
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default DynamicHero