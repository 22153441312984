import React from 'react'
import Navigation from './Navigation'
import ScrollToTop from './components/shared/ScrollToTop'

const App = () => {
  return (
    <>
    <ScrollToTop/>
    <Navigation/>
    </>
  )
}

export default App