import React from 'react'
import TransactionCard from '../components/transaction/TransactionCards'
import DynamicHero from '../components/shared/DynamicHero'

const Transaction = () => {
    return (
        <>
            <DynamicHero
                heading="Financial Instrument Issuance"
                transaction
            />
            <TransactionCard />
        </>
    )
}

export default Transaction