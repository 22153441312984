import React from 'react'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import image from "../assets/logo.png"
import { Link } from 'react-router-dom'
const ForgotPassword = () => {
    return (
        <>
            <Container className='py-5 login'>
                <Col xs={12} lg={6} className='mx-auto'>
                    <div className='text-center'>
                        <Link to="/">
                        <Image src={image} className='mx-auto pb-4' width={200} height={"auto"} />
                        </Link>
                        <h1 className='pb-5 pt-3'>Forget Password</h1>
                    </div>

                    <Card className='border-0 rounded-0 py-lg-5 py-2 px-lg-3 px-1'>
                        <Card.Body>

                            <Form>
                                <Form.Group className="py-4" controlId="formBasicEmail">
                                    <Form.Label>Email<span>*</span></Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>

                                <div className='text-center'>
                                    <Button className='login-btn' type="submit">
                                        Submit
                                    </Button>
                                    <div className='py-2'>
                                        <Link className='text-muted' to={"/"} type="submit">
                                        Back to login
                                        </Link>
                                    </div>

                                </div>
                                <hr />
                                <div className='pt-2 pb-4 text-center'>
                                    <Link className='text-muted text-decoration-none' type="submit">
                                        Don’t have an Account?
                                    </Link>
                                </div>

                                <Row>

                                    <Col xs={12} lg={6}>
                                        <Button className='open-btn my-2 w-100 bg-white' type="submit">
                                        Open Personal Account
                                        </Button>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Button className='open-btn my-2 w-100 bg-white' type="submit">
                                        Open Corporate Account
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Container>
        </>
    )
}

export default ForgotPassword