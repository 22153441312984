import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import mobile from "../../assets/mobile.png"
import { Link } from 'react-router-dom'
const Hero = () => {
    return (
        <>
            <div className="hero">
                <Container>
                    <Row>
                        <Col xs={12} lg={7} className='d-flex align-items-center'>
                            <div className='text-center text-lg-start'>
                                <p className='top-text pt-lg-0 pt-4'>WHAT WE DO</p>
                                <h1 className='py-2'>Participate With Confidence</h1>
                                <p>Truvest Capital Markets GMBH, a leading provider of fintech banking services and trade finance solutions.  Our head office is located in Troisdorf, Germany with offices in Dubai, United Kingdom and USA. With a strong focus on delivering innovative financial solutions, Truvest Capital Markets GMBH is dedicated to helping businesses thrive in the global market. Whether you're looking for tailored banking services or reliable trade finance solutions, Truvest Capital Markets GMBH is committed to providing professional support and expertise. Trust in Truvest for all your financial needs.</p>
                                <Link to={"services"}>
                                <Button  className='my-3 '>Explore</Button>
                                </Link>
                               
                            </div>
                        </Col>
                        <Col xs={12} lg={5}>
                            <div className='text-center'>
                                <Image src={mobile} className='mx-auto' width={"80%"} height={"auto"} />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Hero