import React from 'react';
import { Container } from 'react-bootstrap';
import PageHeading from "../components/shared/PageHeading";

const Disclaimer = () => {
    const items = [
        'Legal Compliance: Truvest Capital Market GmbH is committed to fully complying with all applicable laws and regulations governing the trading of precious metals and commodities. We strive to ensure that all our activities, including our business and trade finance consulting services, are conducted in strict adherence to the legal requirements of the jurisdictions we operate in.',
        'Investment Risks: Trading in precious metals and commodities involves inherent risks, and the value of investments can fluctuate significantly. Past performance is not indicative of future results. Truvest does not provide any guarantees or warranties regarding the profitability or performance of any precious metal or commodity investments.',
        'Business Consulting: Truvest offers business consulting services, including but not limited to market analysis, feasibility studies, and strategic planning. However, our consulting recommendations should not be considered as financial or legal advice, and you should consult with appropriate professionals before implementing any business decisions.',
        'Trade Finance Consulting: Truvest provides trade finance consulting services to facilitate international trade transactions. While we aim to offer reliable advice and solutions, the final responsibility for conducting due diligence and assessing risks in trade-related activities lies with the involved parties.',
        'No Financial Advice: The information provided by Truvest, including but not limited to market analysis, research reports, and investment recommendations, is for general informational purposes only. It should not be considered as financial or investment advice, and you should consult with a qualified financial advisor before making any investment decisions.',
        'Valens Pay License: Truvest Capital Market GmbH operates under the license of Valens Pay for the provision of banking solutions related to our trading and consulting activities. However, this license does not imply any endorsement or responsibility for Truvest’s investment decisions, consulting advice, or performance.',
        'Internet-Based Risks: The use of Truvest’s online platforms, websites, and communication channels may expose you to internet-based risks. While we employ various security measures, we cannot guarantee the absolute security of data transmission and are not liable for any unauthorized access to your information.',
        'Third-Party Content: Truvest may provide links or references to third-party websites, articles, or other content for informational purposes. We do not endorse or take responsibility for the accuracy, reliability, or legality of any third-party content.',
        'Limited Liability: Truvest Capital Market GmbH and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our services or reliance on the information provided.',
        'Changes to Disclaimer: Truvest reserves the right to modify or update this disclaimer at any time without prior notice. Any changes will be effective immediately upon posting on our website or communicating through other means.'
    ];

    return (
        <>
            <Container>
                <PageHeading heading="Disclaimer" />
                <div className='disclaimer'>
                    <h2 className='card-title py-4'>Please read this disclaimer carefully with truvest capital market</h2>
                    
                    <p>Please read this disclaimer carefully before engaging with Truvest Capital Market GmbH (“Truvest”), a company operating in the precious metals and commodities trading sector, as well as offering business consulting and trade finance consulting services. By accessing or using any services provided by Truvest, you hereby acknowledge and agree to the following: </p>
                    <ol>
                        {items.map((item, index) => (
                            <li className='py-3' key={index}>{item}</li>
                        ))}
                    </ol>
                    <p>By continuing to use Truvest’s services, you agree to be bound by this disclaimer. If you do not agree with any part of this disclaimer, please refrain from using our services or platforms.</p>
                    <p>For any questions or concerns regarding this disclaimer or Truvest’s operations, please contact us at info@truvest.de or through phone (+49 2241 328 3999).</p>
                    <p>Date: 1st July 2023</p>
                </div>
            </Container>
        </>
    );
}

export default Disclaimer;
