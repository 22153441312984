import React from 'react'

const ContactInfo = () => {
    return (
        <>
            <div>
                <div className='contact'>
                    <p className='pb-4 pt-3'>Germany Office:</p>
                    <p>Truvest Capital Market</p>
                    <p>Mottmannstr. 1-3 53842 Troisdorf Germany</p>

                </div>
                <div className='contact py-3'>
                    <p>Company Headquarter:</p>
                    <p>Sankt Augustin</p>

                </div>
                <div className='contact py-3'>
                    <p>Registered Court:</p>
                    <p>District Court </p>
                    <p>Siegburg, HRB 15784</p>

                </div>
               
            </div>
        </>
    )
}

export default ContactInfo