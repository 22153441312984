import React from 'react';
import { Container } from 'react-bootstrap';
import PageHeading from '../components/shared/PageHeading';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {


    return (
        <Container>
            <PageHeading heading="Privacy Policy" />
            <div className='disclaimer'>
                <h2 className='card-title py-4'>Truvest Capital Market Privacy Policy Notice</h2>
                <p>This privacy notice applies to Valens Pay Limited using the Trading Name Truvest Capital Market (together “Valens Pay”, “Valens Pay Limited” or “we” or “our”). This notice shows our commitment to protect your privacy rights and sets out the basis on which any personal information we collect from you, or that you provide to us, will be processed by us. It applies to:</p>

                <ul>
                    <li>the website/portal features and services provided to you when you visit our websites, portals or our payment panels our clients may use on their websites;</li>
                    <li>your use of software including terminals, mobile and desktop applications provided by Truvest Capital Market; and</li>
                    <li>email, other electronic messages including SMS, telephone, web chat, website/portal and other communications between you and Truvest Capital Market.</li>
                </ul>

                <p>Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

                <p>Truvest Capital Market takes a “layered approach” to explaining its privacy practices, as often recommended by regulators. This means that we endeavor to initially provide you with the key privacy information in the form of this “short form” privacy notice. To review our complete privacy notice, please see our Comprehensive Privacy Policy below. </p>
                <p>Contact us details are provided at the end of this privacy notice, for feedback or any privacy enquiries you may have.</p>
                <h5>INFORMATION WE MAY COLLECT FROM YOU</h5>
                <p>You may provide some information to us, for example when you enquire for or make an application for the Truvest Capital Market Services, register to use and/or use any Truvest Capital Market Services, by filling in forms on one of our websites, participating in one of our events or by corresponding with us by phone, e-mail, web chat or otherwise. We may collect some of it through automatic means, for example by using cookies when you visit our websites. Read our <Link to="#">Cookie Statement</Link> to know more. We may also obtain data about you from third parties, like credit reference and fraud prevention agencies.</p>

                <h5>USES OF THE INFORMATION</h5>
                <p>We use information we collect about you to provide you with the products we offer, to notify you about changes to our products and to improve our products. We also use this information to provide you with information about other products we or selected third parties offer which are similar to the ones you have used or enquired about or we think may be of interest to you. If you use one of our financial products, we will also use your information to assess your financial situation and to try to identify and prosecute possible frauds.</p>

                <h5>DISCLOSURE OF YOUR INFORMATION</h5>
                <p>We will not disclose your personal data to anyone except as described in this notice. We may share your personal information with third parties to provide you with the products we offer, including service providers, credit reference agencies and financial institutions. We may also share your personal information with third parties to prevent crime and reduce risk, if required to do so by law, where we deem it appropriate to do so, to respond to legal process or to protect the rights or property of Truvest Capital Market, our customers or others.</p>

                <h5>WHERE WE STORE YOUR PERSONAL DATA AND DATA SECURITY</h5>
                <p>The information Truvest Capital Market collects from you may be transferred to, stored at and processed by recipients located in destinations outside Canada. These countries may have data protection standards that are different to (and, in some cases, lower than) those in which you reside. We will take all steps necessary to ensure that the recipients will use and protect your information in the same secure way that we do.</p>

                <p>If we have given you (or if you have chosen) a password, access code or any other secure means or access or authentication which enables you to access certain parts of our site, you are responsible for keeping this password confidential and complying with our instructions. You must not share your password or other login credentials with anyone, and you hereby authorize Truvest Capital Market to act upon instructions and information from any person that enters your credentials.</p>

                <p>The transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site, unless you are communicating with us through a secure channel provided by us. Once we have received your information, we will use strict procedures and security features in an effort to prevent unauthorized access.</p>

                <h5>YOUR RIGHTS</h5>
                <p>Where applicable, you may have certain rights under data protection legislation, including the right to access, correct, update or delete your personal information; object to or restrict its processing; request to transfer certain of your personal information to another service provider (so-called, data portability); or to revoke any consent you have given.</p>

                <p>However, please note that there are exceptions to those rights. For the full explanation of the rights available, please refer to our Comprehensive Privacy Notice.</p>

                <h5>AUTOMATED DECISION MAKING</h5>
                <p>Automated decisions mean that a decision concerning you is made automatically on the basis of a computer determination (using software algorithms), without our human review.</p>

                <p>For example, we use automated decisions to carry out anti-fraud checks on the transactions you perform. However, if you are in the European Union, you have the right to object to the automated decision and have it manually reviewed based also on information that you can provide. You can contact us at the details below to exercise your right.</p>

                <h5>LEGAL BASIS FOR PROCESSING</h5>
                <p>Truvest Capital Market will process your personal information only when we have one of the following grounds:</p>
                <ul>
                    <li>On the basis of your consent, for example to send you marketing messages;</li>
                    <li>Where necessary to enter into a contract with you and perform our obligations under that contract;</li>
                    <li>Where we have a legitimate interest, subject to such processing not overriding your own rights and freedoms;</li>
                    <li>Where we have a legal obligation to collect your personal information or otherwise need your personal Information to protect your vital interests or those of another person; or</li>
                    <li>Exceptionally, when necessary, in the public interest, for example when law enforcement agencies request information to investigate a crime.</li>
                </ul>

                <h5>CHANGES TO OUR PRIVACY NOTICE</h5>
                <p>Any changes we may make to our privacy notice in the future will be posted on this page and, where appropriate, you will be notified by e-mail. Please check back frequently to see any updates or changes to this privacy notice.</p>

                <h5>CONTACT US</h5>
                <p>At Truvest Capital Market, we welcome all comments, queries and requests relating to our use of your information. Our Data Protection Officer is the person in charge of ensuring our company adheres to this privacy notice. This person is also the main contact for our Data Protection Supervisory Authority. If you wish to exercise any of your rights or receive further information concerning Valens Pay (to which this notice applies), you should write to our Data Protection Offer (DPO) at <Link to="mailto:dpo@valenspay.com" className='text-muted text-decoration-none'>dpo@valenspay.com</Link> or at the address below:</p>

                <h5>PRIVACY NOTICE (COMPREHENSIVE VERSION)</h5>
                <p>This privacy notice applies to Valens Pay Limited using the Trading Name Truvest Capital Market (together “Valens Pay”, “Valens Pay Limited” or “we” or “our”). This notice shows our commitment to protect your privacy rights and sets out the basis on which any personal information we collect from you, or that you provide to us, will be processed by us. It applies to:</p>

                <ul>
                    <li>the website/portal features and services provided to you when you visit our websites, portals or our payment panels our clients may use on their websites;</li>
                    <li>your use of software including terminals, mobile and desktop applications provided by Truvest Capital Market; and</li>
                    <li>email, other electronic messages including SMS, telephone, web chat, website/portal and other communications between you and Truvest Capital Market.</li>
                </ul>

                <p>The Truvest Capital Market Portal Website is not intended for the use by anyone under the age of 18. If you are below the age of 18, you may not use the Truvest Capital Market Portal.</p>

                <p>We ask that you read this Policy carefully as it contains important information about what to expect when we collect personal information about you and how we will use your personal data.</p>

                <h5>1. INTRODUCTION</h5>
                <ul>


                    <li>The aim of this Policy is to set out the purposes for which, and the manner in which, we may collect and use personal information obtained about you. By either registering as a user of any services provided by us on the Truvest Capital Market Portal, and/or by using the Truvest Capital Market Portal, you consent to this use by us. You may choose not to provide any information to us, however Truvest Capital Market may be unable to provide services.</li>

                    <li>Your use of Truvest Capital Market Portal is also governed by our online Terms and Conditions. Please ensure you have read the Terms and Conditions as well as this Policy. Please pay attention to the fact that Truvest Capital Market may update this Privacy policy from time to time, therefore please do review it regularly.</li>

                    <li>Your personal data controller is Valens Pay Limited as specified in the Terms & Conditions. We have appointed a data protection officer (“DPO”) and you can contact our DPO via email at <Link to="mailto:dpo@valenspay.com">dpo@valenspay.com</Link>.</li>

                    <li>We comply with General Data Protection Regulation (“GDPR regulation”), the applicable data protection laws of Canada being the Protection of Personal Information and Electronic Documents Act (PIPEDA). We process your personal information based on our Terms of use/business (Agreement/s) with you to comply with our legal obligations, to satisfy our legitimate interests as described above and to satisfy on your consent.</li>

                    <li>Security is highly important to us. We take all reasonable measures to protect personal data from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction, including industry standard security and encryption features.</li>

                    <li>If the Profile is opened by a legal person, Head or other representative of the Client clearly inform data subjects (Beneficial owners, Head, etc.) about transfer of their data to Us and ensure to present this Privacy policy to them.</li>
                </ul>
                <h5>2. DEFINITIONS</h5>
                <div>
                    <p><strong>"Account"</strong> shall mean the contractual arrangement wherein a Truvest Capital Market member has accepted our Terms of Use and Privacy Policy, and received approval to use the Truvest Capital Market Services, including the purchase and sale of Bitcoins and to perform associated Transactions.</p>
                    <p><strong>"AML"</strong> shall mean anti-money laundering and counter-terrorism financing regulations.</p>
                    <p><strong>"DPL"</strong> shall mean data protection law applicable to Truvest Capital Market, including the Canadian Data Protection Act -The Protection of Personal Information and Electronic Documents Act (PIPEDA) which applies to the processing of Personal Data by Truvest Capital Market.</p>
                    <p><strong>"Personal data"</strong> shall mean any information that identifies an individual, such as name, address, e-mail address, trading information, and banking details. Personal Data does not include anonymized and/or aggregated data that does not identify a specific user.</p>
                    <p><strong>"Service(s)"</strong> shall mean the technological platform, functional rules and market managed by Truvest Capital Market to permit Sellers and Buyers to perform purchase and sale transactions of Bitcoins and/or other Cryptocurrencies.</p>
                    <p><strong>"Supervisory institutions"</strong> shall mean FINTRAC Canada, or other institutions that directly or indirectly supervise our activity.</p>
                    <p><strong>"Truvest Capital Market platform"</strong> shall mean hardware and software technologies used by Truvest Capital Market to provide the Truvest Capital Market as set out in our Terms of Use.</p>
                    <p><strong>"Transaction"</strong> shall include the following:
                        <ul>
                            <li>The action between the Member to Buy and/or Sell cryptocurrencies through the Service for currencies at the market rate.</li>
                            <li>The transfer of cryptocurrencies between Members (“Transfer Transaction”);</li>
                            <li>The transfer of currencies among Members (“Currency Transfer Transaction”); and</li>
                            <li>Truvest Capital Market may not offer all these transaction types at this time.</li>
                        </ul>
                    </p>
                    <p><strong>"You" or "Your" or "the Client"</strong> shall mean the natural person who is contracting with us, making payment to our System or representative, Beneficial owner on whose behalf the Personal data is provided.</p>
                </div>

                <p>Other terms in this Privacy policy are used as defined in our online Terms & Conditions.</p>

                <h5>3. THE PURPOSES OF PROCESSING</h5>
                <h6>1. Data collection and use of personal information</h6>
                <p>We collect and process Your data for the following purposes:</p>
                <ul>
                    <li>Provision of Payment services;</li>
                    <li>Verification of the identity;</li>
                    <li>Prevention of fraud, money laundering, counter-terrorist;</li>
                    <li>Implementation of other obligations under AML requirements;</li>
                    <li>Notification about changes in our Services and dispute prevention;</li>
                    <li>Dispute settlement;</li>
                    <li>Marketing;</li>
                   
                </ul>
                <p>2. Truvest Capital Market is required by law to verify your identity, monitor transactions of the Client, prevent fraud, money laundering, counter-terrorist financing, other financial crimes, properly implement obligations of payment service provider and comply with AML regulations.</p>

                <p>3. For this purpose, We may collect below data about the natural person client or representative of the legal person client:</p>

                <ul>
                    <li>Data about your identity: name, surname, personal identification number or other unique sequence of symbols assigned to the person for identification purposes, date of birth, personal identification document number, copy of personal identification document, nationality or the state which has issued the personal identification document, country of residence, ID number in Truvest Capital Market system, Account number in Truvest Capital Market, face photo, etc. (“Identity data”);</li>
                    <li>Contact data: address, phone number, email address, etc. (“Contact data”);</li>
                    <li>KYC data: account number on other payment service providers, checks on public and private registries, fraud, money laundering, counter-terrorist financing and other financial crimes prevention agencies, employer, position, address, occupation, used Services, payment and other transactions, turnover, source of funds, countries from to which funds will be received / transferred, information of political involvement, other data of monitoring of Client’s activity, etc. ("KYC data");</li>
                    <li>Device information: OS of device, browser type and version used for access to Profile, IP and location, time zone setting, etc. ("Device information");</li>
                    <li>Financial information, such as bank account numbers, bank statement, and trading information; ("Financial information");</li>
                    <li>Online Identifiers: Geo location/tracking details, browser fingerprint, OS, browser name and version, and/or personal IP addresses; ("Online Identifiers");</li>
                    <li>Usage Data: Survey responses, information provided to our support team, public social networking posts, authentication data, security questions, user ID, click-stream data and other data collected via cookies and similar technologies. Please read our <Link to="#">Cookie Statement</Link> for more information. ("Usage data");</li>
                    <li>Truvest Capital Market payment card information: number and CVC code of payment card, PIN code, relevant expiry dates, etc. ("Payment card information");</li>
                    <li>Any other information that you or your customer provide to us.</li>
                </ul>

                <p>4. We may also collect and process Identity data about Beneficial owners of legal entity. Also, Identity data might be collected and processed about Head of legal entity if Profile has been opened by another representative of the Client.</p>

                <p>5. We may collect data from You and from third parties, We may search publicly available information about You in order to Verify your identity, process transactions, detect and prevent fraud or other similar purposes.</p>

                <p>6. We collect data mentioned above as We are required by law and We need this information as to enter contractual relationship with the Client. If you or a representative of legal entity won’t provide required data, We may refuse to enter into contractual relationship and provide Services for the Client.</p>

                <p>7. We may provide your data to: supervisory institutions, banking and financial services partners, other payment service providers, payment card manufacturing/ personalization and delivery companies, companies that provide identification and sanctions/watch lists screening, transaction monitoring services, government institutions, attorneys, bailiffs, pre-trial investigation institutions, courts, auditors, debt recovery agencies, companies that handle joint debtor data files, fraud, money laundering, terrorist financing and other financial crimes prevention agencies, other subjects that have legitimate interest or Your acceptance.</p>

                <p>8. We process personal data for as long as is necessary for the relevant purpose(s) or as required by law (e.g., our tax obligations, AML obligations and obligations to retain business records).</p>

                <p>9. We may provide your data to third countries as to perform Your payment transaction to third country recipient or using third country partners services for the payment transaction, in which case we ensure that there is adequate protection for such personal data either by ensuring that the transfer is made on the basis of an adequacy decision (such as the EU-CH adequacy decision), or by use of safeguards described in Art. 6 of the SDPA respectively Article 46 of the GDPR. In some cases, we may transfer your data to countries without adequate data protection, provided that the requirements under the applicable data protection laws, such as your explicit consent, are met.</p>

                <p>4. Notification about changes in our Services and dispute prevention.</p>
                <ul>
                <li>We are required by law to notify You about changes in our Services and Terms & Conditions for provision of services. These notifications are not marketing material and are to be provided as required by law. According to this You shall not have the right to object receiving such notifications. For this purpose, we may process Your Contact data.</li>

                <li>We process above mentioned data as We are required by law and for the performance of the contract with the client.</li>

                <li>You can also refer to Us requiring information from Us, to consult with Us about our Services or to inform Us about unauthorized payments, demand to block Your payment instrument or ask to provide other information. You may contact Us by phone or email. As You contact Us We may process Your Identity data, Contact data, Device data, also record our phone conversations and store content of our correspondence and history of it.</li>
                </ul>
                <p>Above mentioned data is processed:</p>
                <ol>
                    <li>As You refer to Us by phone: for the purposes of the legitimate interests pursued by Us to prevent disputes between you and Us about proper provision or receiving of information.</li>
                    <li>As You refer to Us by email or letter: data is collected for the law requirement purposes and for the purposes of the legitimate interests pursued by Us to prevent disputes between you and Us about proper provision or receiving of information.</li>
                </ol>

                <p>10. Your data may be provided to supervisory institutions, attorneys, pre-trial investigation institutions, courts, auditors, debt recovery agencies, companies that handle joint debtor data files, other subjects having Your acceptance.</p>

                <h5>11. Dispute Settlement</h5>
                <p>1. Before starting a dispute with Us firstly You should contact Truvest Capital Market so that we can handle your complaint and satisfy it benevolently if it is well grounded. If you are not satisfied with our reply, you can address your complaint to our licensing authority according to Our Complaints policy.</p>

                <p>2. As the dispute may arise, for dispute settlement purposes We may collect and process such data: Identity data, Contact data, KYC data, also data provided in Your claim and its supplements, other data needed to investigate Your claim. The data is processed as We are required by the law.</p>

                <p>3. Your data may be provided to supervisory institutions, attorneys, pre-trial investigation institutions, courts, auditors, debt recovery agencies, companies that handle joint debtor data files, other subjects having your acceptance.</p>

                <h5>6. Marketing</h5>
                <ul>

              
                <li>We may provide you by email the information about our current and new Services, other goods and services We offer that are similar to those that you have already used or inquired about, to provide you or permit selected third parties to provide you information about goods or services We feel may interest You.</li>

                <li>Email for our direct marketing purposes (newsletters) will be processed only with Your separate acceptance.</li>

                <li>You have the right at any time to object to your email usage for direct marketing purposes.</li>

                <li>We may provide your email-address to email marketing service providers to send you newsletters on our behalf.</li>

                <li>We hereby inform you that we may also use your personal data (such as used services, location, IP, behavior, etc.) to form a view on what We think You may want or need, or what may be of interest to you and show You adverts in our webpage.</li>

                <li>We may also provide anonymized information to advertisers to promote our products and services. Therefore, such anonymized information would be used to help our advertising partners provide a targeted campaign, relevant for a sub-section of our Clients.</li>

                <li>You have the right at any time to object to your data usage for marketing purposes.</li>

                <li>We may contract (hire) certain service providers, i.e., data processors, to process your personal data. These may be companies providing data storage, server and/or communication services, companies developing and maintaining software, companies providing marketing services and analysis of activities online, online traffic and website analysis, statistics services and other service providers. Your personal data may be transferred to these data processors only when and solely to the extent necessary for the provision of the respective services of these data processors.</li>
                </ul>
                <h5>7. Use of Cookies and Similar Technology</h5>
                <p>1. Truvest Capital Market receives and stores certain information automatically whenever you interact with Truvest Capital Market. The Truvest Capital Market website uses cookies.

               Cookies are small text files that are placed on your computer by websites that you visit, including Truvest Capital Market. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</p>

                <p>2. Cookies are typically stored on your computer’s hard drive. Information collected from cookies is used by us to evaluate the effectiveness of our website, analyze trends, and administer the Platform. The information collected from cookies allows us to determine such things as which parts of our website are most visited and difficulties our visitors may experience in accessing our website. With this knowledge, we can improve the quality of your experience on the Platform by recognizing and delivering more of the most desired features and information, as well as by resolving access difficulties. We also use cookies and/or a technology known as web bugs or clear gifs, which are typically stored in emails to help us confirm your receipt of, and response to, our emails and to provide you with a more personalized experience when using our website. The information we collect includes:</p>

                <ul>
                    <li>Technical information, including the Internet protocol (IP) address used to connect your computer or device to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system platform;</li>
                    <li>Information about your visit or whether you opened an email, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products or services you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks and mouse-overs) and methods used to browse away from the site page and any phone number used to call our customer service number.</li>
                </ul>

                <p>3. We use third party service provider(s), to assist us in better understanding the use of our website. Our service provider(s) will place cookies on the hard drive of your computer and will receive information that we select that will educate us on such things as how visitors navigate around our site, what products are browsed, and general Transaction information. Our service provider(s) analyzes this information and provides us with aggregate reports. The information and analysis provided by our service provider(s) will be used to assist us in better understanding our visitors’ interests in our website and how to better serve those interests. The information collected by our service provider(s) may be linked to and combined with information that we collect about you while you are using the Platform. Our service provider(s) is/are contractually restricted from using information they receive from our website other than to assist us.</p>

                <p>4. If you want to avoid using cookies altogether, you can disable cookies in your browser. However, disabling cookies might make it impossible for you to use certain features or our website or services, such as logging on to your Truvest Capital Market Account or making transactions. Your use of our website or service with a browser that is configured to accept cookies constitutes an acceptance of our and third-party cookies.</p>

                <p>5. For more information about cookies, the types of cookies we use and how we use them please see our Cookie Statement.</p>

                <h5>8. Data processing</h5>
                <p>1. Your data will be processed by automated means, as well as to manual processing, including automated individual decision-making and profiling, for various processing purposes.</p>

                <p>2. Automated decision making and profiling might be used for Verification of your identity, monitoring Your operations and activity in the Profile, predicting other aspects of Your behavior. Such procedures are necessary according to AML requirements and necessary for entering and performing business relationship between Us and the Client. Truvest Capital Market implements suitable measures to safeguard Your rights and freedoms and legitimate interests, including the right to obtain human intervention. You also have the right to express your point of view and to contest the decision.</p>

                <p>3. The information We process about You is stored on our secure servers. We use all needed technical, organizational and other means to protect your data.</p>

                <h5>9. DISCLOSING AND TRANSFERRING PERSONAL DATA</h5>
                <p>We may disclose your Personal Data to third parties and legal and regulatory authorities and transfer your Personal Data outside Canada as described below.</p>

                <p>1. Disclosures to Third Parties</p>
                <p>There are certain circumstances where we may transfer your Personal Data to employees, contractors and to other parties:</p>
                <ul>
                    <li>We may share information about you with other members of our group of companies so we can provide the best service across our group. They are bound to keep your information in accordance with this Privacy Policy;</li>
                    <li>We may also share your information with certain contractors or service providers. They may process your Personal Data for us, for example, if we use a marketing agency. Other recipients/service providers include advertising agencies, IT specialists, database providers, backup and disaster recovery specialists, email providers or outsourced call centers. Our suppliers and service providers will be required to meet our standards on processing information and security. The information we provide them, including your information, will only be provided in connection with the performance of their function;</li>
                    <li>We may also share your information with certain other third parties. We will do this either when we receive your consent or because we need them to see your information to provide products or services to you. These include credit reference agencies, anti-fraud databases, screening agencies and other partners we do business with.</li>
                    <li>We may share your information to improve and develop our business, including without limitation to optimize our websites/portals, products and services. This may include using information you insert into forms but do not submit to us, for example by using that information to optimize our website(s) and contacting you for customer services purposes in relation to that form. We can also use your personal information to develop and test new products and services.</li>
                </ul>

                <p>2. Your Personal Data may be transferred to other third-party organizations in certain scenarios:</p>
                <ul>
                    <li>If we’re discussing selling or transferring part or all of our business – the information may be transferred to prospective purchasers under suitable terms as to confidentiality;</li>
                    <li>If we are reorganized or sold, information may be transferred to a buyer who can continue to provide services to you;</li>
                    <li>If we’re required to by law, or under any regulatory code or practice we follow, or if we are asked by any public or regulatory authority – for example the Police;</li>
                    <li>If we are defending a legal claim your information may be transferred as required in connection with defending such claim.</li>
                    <li>To comply with local and national laws;</li>
                    <li>To send marketing messages, to provide you with the information on products and services you have requested or we think may be of interest to you; to obtain your views on our goods, services and our website(s); in respect of marketing, market research and similar activities, we may use your personal information for such purposes whether or not you are accepted as or continue to receive Truvest Capital Market Services. If you no longer wish to receive marketing or promotional information from Truvest Capital Market, you can always stop it;</li>
                    <li>To manage and mitigate our credit risks. if you apply for one of our financial products, we will assess your financial position (and / or of your business), to the extent this is provided for in the applicable Terms of Use. This credit check will also affect any linked parties such as directors, shareholders and principals. We can do so by receiving and sharing information from and with credit reference agencies and fraud prevention agencies. This credit check will affect also anyone with whom you have a joint account or similar financial association. If it is a joint application, and such link does not already exist, then one may be created. These links will remain until you file a “notice of disassociation” at the credit reference agencies. For your information, the agencies will record our enquiries which may be seen by other companies who make their own credit enquiries; and a “footprint” may be placed on your credit file, whether or not you are accepted as our customer. If you are a director, we will seek confirmation from credit reference agencies that the residential address that you provide is the same as that held by the relevant companies’ registry (where applicable). In addition, where you take services from us, we will give information on how you manage your accounts to the credit reference agencies. If you do not repay any monies in full and on time, credit reference agencies will record the outstanding debt and may share this information with other organization that perform checks similar to ours. Records remain on file at such agencies for 6 years after they are closed, whether settled by you or defaulted. If you would like further information on our use of credit reference agencies, please Contact Us;</li>
                    <li>To prevent, detect and prosecute fraud or crime, Truvest Capital Market may participate in anti-fraud initiatives, which involve assessing you (and/or your customers) and monitoring your transactions and/or locations, to detect patterns requiring investigations or otherwise profile and assess the likelihood of fraud occurring. We can do so utilizing products and services from third parties. Besides, if you give us false or inaccurate information about you, or we identify or suspect a fraud or a crime, we may pass your information to fraud prevention agencies and to law enforcement agencies, and we may decide to take legal action against you;</li>
                    <li>To prevent or mitigate information security risk;</li>
                    <li>To manage and enforce our rights, Terms of Use or any other contracts with you (and/or your business), including to manage any circumstances where transactions, rewards or points are disputed; manage, investigate and resolve complaints; or recover debt or in relation to your insolvency;</li>
                </ul>

                <p>3. Your Personal Data may be shared if it is made anonymous and aggregated, as in such circumstances the information will cease to be Personal Data.</p>

                <p>4. Your information will not be sold, exchanged, or shared with any third parties without your consent, except to provide Truvest Capital Markets Services or as required by law.</p>

                <p>5. The third-party service providers of Truvest Capital Market are contractually bound to protect and use such information only for the purposes for which it was disclosed, except as otherwise required or permitted by law. We ensure that such third parties will be bound by terms complying with DPL.</p>

                <p>6. Specifically, Your Personal Data may be transferred to third-party organizations in certain scenarios:</p>
                <ul>
                    <li>Analyzing ID documents, and other AML-KYC related documents and information to assist us in satisfying our KYC obligations. It will always be processed and stored data in ISO 27001-certified data centers worldwide.</li>
                    <li>When you top up through the Platform by wire transfer or credit card, your Personal Data may be shared in ISO 27001-certified data centers worldwide.</li>
                    <li>When you hold, buy, sell or transfer cryptocurrency through the Platform, your Personal Data may be shared in ISO 27001-certified data centers worldwide.</li>
                    <li>When you open bank account, your Personal Data may be shared to a provider specified on the Platform.</li>
                    <li>When you apply for a debit card through the Platform, Your Personal Data may be shared for the approval process.</li>
                </ul>

                <h5>10. Disclosures to Legal Authorities</h5>
                <p>1. Truvest Capital Market may be required by law to pass information about you to regulatory authorities and law enforcement bodies worldwide, or we may otherwise determine that it is appropriate or necessary to do so. Such disclosures may also include requests from governmental or public authorities, or with commercial organizations with whom you may have had dealings and whom are seeking to mitigate fraud risk, or for the purposes of litigation or legal process, national security or where we deem it in the national or public interest or otherwise lawful to do so. As such, we may share your Personal Data with law enforcement, data protection authorities, government officials, and other authorities when:</p>
                <ul>
                    <li>Compelled by court order, or other legal procedure.</li>
                    <li>Disclosure is necessary to report suspected illegal activity.</li>
                    <li>Disclosure is necessary to investigate violations of this Privacy Policy or our Terms of Use.</li>
                </ul>

                <h5>11. International Transfers of Personal Data</h5>
                <p>We store and process your Personal Data in data centers around the world, wherever Truvest Capital Market facilities or service providers are located. As such, we may transfer your Personal Data outside of Canada. Some of the countries to which your Personal Data may be transferred for these purposes are located outside Canada, and do not benefit from Canadian Data Protection rules and laws. Such transfers are undertaken in accordance with our legal and regulatory obligations and appropriate safeguards under DPL will be implemented, such as standard data protection clauses with data recipients or processors approved by competent authorities. A copy may be requested at the address set out in the Contact Us section.</p>

                <h5>12. YOUR RIGHTS</h5>
                <p>Please be informed about your rights under Data Protection regulations in relation to Your personal data:</p>
                <ul>
                    <li>Right to Information: The right to ask us for information about what personal data of Yours is being processed and the rationale for such processing.</li>
                    <li>Right of Access: The right to access your personal data;</li>
                    <li>Right of Rectification: The right of rectification of inaccurate personal data concerning you, and the right to have incomplete personal data completed. Please be advised that we may need to verify the accuracy of the new data You provide to Us;</li>
                    <li>Right of Erasure (the right to be forgotten): The right of erasure of personal data concerning You if there is no convincing reason for Us continuing to process it. Please be advised that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to You, if applicable, at the time of your request. Please note that Under GDPR Article 17(3)(b), legal requirements take precedence over the right to be forgotten. From an AML perspective, both customer due diligence and transaction records have to be retained for a minimum of a set number of years after the end of the customer relationship and Truvest Capital Market will retain Your data, in line with AML regulations, for a minimum of 10 years. In this context, the right to be forgotten would only be enforceable after this period had ended. We will then take reasonable steps to securely destroy such information or permanently de-identify it.</li>
                    <li>Right of Restriction of Processing (Right to withdraw Consent): The right to obtain from Us restriction of processing if there is legitimate reason. Where processing has been restricted, such personal data shall, with the exception of storage, only be processed with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the EU or of a Member State.</li>
                    <li>Right to Data Portability: The right to receive the personal data concerning You, which you provided to Us and have the right to transmit data to another controller. Please be advised that this right only applies to information which You provided for Us.</li>
                    <li>Right to object: The right to object at any time to processing of personal data concerning You which is processed for the purposes of our legitimate interests. We shall no longer process the personal data unless the We demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of You or for the establishment, exercise or defense of legal claims. Where personal data is processed for marketing purposes, You shall have the right to object at any time to processing of personal data concerning You for such marketing.</li>
                    <li>Right to withdraw consent: The right to withdraw Your consent at any time where we are relying on consent to process Your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. In some cases, if you withdraw Your consent, We may not be able to provide the Services to You.</li>
                    <li>Right to complain: You have right to make a complaint in regard to the privacy or data processing issues at any time. You can complain in the place where the alleged breach of data protection law has taken place. In Canada The key data protection statutes -Federal -the Personal Information Protection and Electronic Documents Act 2000('PIPEDA'); and in British Columbia –the Personal Information Protection Act, SBC 2003 c 63(‘BC PIPA’). We would, however, appreciate the chance to deal with Your concerns before You approach the supervisory authority so please contact Us in the first instance. You can contact our DPO via email at <Link to="mailto:dpo@valenspay.com">dpo@valenspay.com</Link>.</li>
                </ul>

                <h5>13. SECURITY OF YOUR DATA</h5>
                <p>1. We take protecting of your personal data very seriously and strive to apply all necessary organizational and technological security measures.</p>

                <p>2. We use a variety of security measures to ensure the confidentiality of your Personal Data, and to protect your Personal Data from loss, theft, unauthorized access, misuse, alteration or destruction. These security measures include, but are not limited to:</p>
                <ul>
                    <li>Password protected directories and databases;</li>
                    <li>Secure Sockets Layered (SSL) technology to ensure that your information is fully encrypted and sent across the Internet securely;</li>
                    <li>PCI Scanning to actively protect our servers from hackers and other vulnerabilities.</li>
                </ul>

                <h6>3. All financially sensitive and/or credit information is transmitted via SSL technology and encrypted in our database. Only authorized Truvest Capital Market personnel are permitted access to your Personal Data, and these personnel are required to treat the information as highly confidential. The security measures will be reviewed regularly in light of new and relevant legal and technical developments.</h6>

                <p>4. We do not ask for financial or payment information, such as your credit card number, passcode, account number or pin number, in an e-mail, text or any other communication that we send to you. Please always check that any website on which you are asked for financial or payment information in relation to our reservations or services is operated by Truvest Capital Market. If you do receive a suspicious request, do not provide your information and report it by contacting one our member service representatives as set in this Privacy Policy.</p>

                <p>5. You are responsible for keeping your account passcode, membership numbers and pin numbers safe and secure. Do not share those with anyone. If there is an unauthorized use or any other breach of security involving your information, you must notify us below as soon as possible.</p>

                <p>6. Please note, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your information transmitted to our site, unless you are communicating with us through a secure channel that we have provided. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access. By browsing the Website and/or using the services of the Company you expressly acknowledge and agree that any personal information or other data provided by you to us or third persons is provided at your own risk.</p>

                <h5>14. FINAL PROVISIONS</h5>
                <p>1. This notice is global in scope but is not intended to override any legal rights or prohibitions in any territory where such rights or prohibitions prevail. In such event, the rights and obligations set out in this notice will apply, subject only to amendment under any applicable local law having precedence.</p>

                <p>2. We have the right to adjust the provisions of this Privacy Policy. The published version will always be the valid version. It will at all-time be your responsibility to ensure, you are in agreement with the valid version of this Privacy Policy.</p>

                <p>This Privacy Policy was last updated in April 2023.</p>

            </div>
        </Container>
    );
};

export default PrivacyPolicy;
