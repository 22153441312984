import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/global.css"
import Header from './components/shared/Header';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Footer from './components/shared/Footer';
import 'react-toastify/dist/ReactToastify.css';
const AppWrapper = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/login'&& location.pathname !== '/forgetpassword' && <Header />}
      <App />
      <Footer />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  </BrowserRouter>
);
